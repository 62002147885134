const _temp0 = require("./images/shop/item1.png");
const _temp1 = require("./images/shop/item10.png");
const _temp2 = require("./images/shop/item11.png");
const _temp3 = require("./images/shop/item12.png");
const _temp4 = require("./images/shop/item13.png");
const _temp5 = require("./images/shop/item14.png");
const _temp6 = require("./images/shop/item15.png");
const _temp7 = require("./images/shop/item16.png");
const _temp8 = require("./images/shop/item17.png");
const _temp9 = require("./images/shop/item18.png");
const _temp10 = require("./images/shop/item19.png");
const _temp11 = require("./images/shop/item2.png");
const _temp12 = require("./images/shop/item20.png");
const _temp13 = require("./images/shop/item21.png");
const _temp14 = require("./images/shop/item22.png");
const _temp15 = require("./images/shop/item23.png");
const _temp16 = require("./images/shop/item24.png");
const _temp17 = require("./images/shop/item25.png");
const _temp18 = require("./images/shop/item3.png");
const _temp19 = require("./images/shop/item4.png");
const _temp20 = require("./images/shop/item5.png");
const _temp21 = require("./images/shop/item6.png");
const _temp22 = require("./images/shop/item7.png");
const _temp23 = require("./images/shop/item8.png");
const _temp24 = require("./images/shop/item9.png");
module.exports = {
  "item1": _temp0,
  "item10": _temp1,
  "item11": _temp2,
  "item12": _temp3,
  "item13": _temp4,
  "item14": _temp5,
  "item15": _temp6,
  "item16": _temp7,
  "item17": _temp8,
  "item18": _temp9,
  "item19": _temp10,
  "item2": _temp11,
  "item20": _temp12,
  "item21": _temp13,
  "item22": _temp14,
  "item23": _temp15,
  "item24": _temp16,
  "item25": _temp17,
  "item3": _temp18,
  "item4": _temp19,
  "item5": _temp20,
  "item6": _temp21,
  "item7": _temp22,
  "item8": _temp23,
  "item9": _temp24
}