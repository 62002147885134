import gallery1 from './images/gallery/gallery1.jpeg'
import gallery2 from './images/gallery/gallery2.jpeg'
import gallery3 from './images/gallery/gallery3.jpeg'
import gallery4 from './images/gallery/gallery4.jpeg'
import gallery5 from './images/gallery/gallery5.jpeg'
import gallery6 from './images/gallery/gallery7.jpeg'

//shop items
import * as images from './images/shop/*.png'
import cart from './images/grocery-store.png'
import delete_icon from './images/delete.png'

const itemsList = [
    'Maganjo millet flour (uganda)',
    'Unico crushed tomotoes',
    'home fresh hausa koko flour',
    'geisha markerel in tomatoe sauce',
    "mama's choice fufu mix (plantain)",
    'tropiway fufu flour (plantain)',
    'grace oxtail marinade',
    'leemex fufu flour (cocoyam)',
    'goya seasoning adobo pepper',
    'quaker white oats',
    'sabwa deluxe puff puff mix',
    'de rica tomatoe paste',
    'tom brown ceremix',
    'neat fufu flour (cocoyam)',
    'walkerswood jamaican jerk seasoning',
    'titus sardine',
    'starkist tuna flakes',
    'salsa tomatoe paste',
    'grace jerk seasoning',
    'milo chocolate drink',
    'brown gold cocoa powder',
    'neat fufu flour (plantain)',
    'dried cat fish',
    'plantain - fresh produce',
    'Cerelac infant cereal'

]


const shop_gallery = document.querySelector('.shop_gallery')
const gallery = document.querySelector('.gallery')
const close = document.querySelector('.close')
const image = document.querySelector('.image img')
const left_arrow = document.querySelector('.left_arrow')
const right_arrow = document.querySelector('.right_arrow')
const featured_trey = document.querySelector('.featured_trey')
const item = document.querySelector('.items')
const shopping_cart = document.querySelector('.shopping_cart')
const cart_section = document.querySelector('.cart_section')
const close_cart = document.querySelector('.close_cart')
const items_container = document.querySelector('.items_container')
const interval_time = 2000
const mobile_view = window.matchMedia('(max-width:500px)')
const total_feature = 2
var items_number = document.querySelector('.items_number span')
var feature_index = 0
var value = 0
var move = 25.33
var index = 0
var new_item_number = Number(items_number.textContent)
var id = 0
var item_number_key = 'number_key'
const cart_key = 'cart_key'
var cartArray = []


shopping_cart.onclick = () => {
    cart_section.style.right = 0
}

close_cart.onclick = () => {
    cart_section.style.right = '-20%'
}

 // localStorage global codes here...
 var storage_item_number = localStorage.getItem(item_number_key)
 if(storage_item_number == null){
     localStorage.setItem(item_number_key,0)
     
 }

 const updateDisplay = ()=>{
    var storage_item_number = localStorage.getItem(item_number_key)
    items_number.textContent = storage_item_number
 }

 updateDisplay()

 //Cart items in local storage codes begin here...
 const get_cart_key = localStorage.getItem(cart_key)
 const new_cart_array = []
 const stringify_array = JSON.stringify(new_cart_array)
 const convert_to_array = JSON.parse(get_cart_key)
 
 if(get_cart_key == null){
    localStorage.setItem(cart_key,stringify_array)
 }

 const clickOnce_key = 'click_once'
 var click_once = false
 const clickOnce=()=>{
    var get_clickOnce = localStorage.getItem(clickOnce_key)
     
     if(get_clickOnce == null){
         localStorage.setItem(clickOnce_key,click_once)
     }
     
 }
 
 clickOnce()


 

try {

    const itemsArray = Object.keys(images)
    itemsArray.sort((a, b) => {
        let itemA = parseInt(a.replace('item', ''), 10)
        let itemB = parseInt(b.replace('item', ''), 10)

        return itemA - itemB
    })

  



    itemsArray.forEach((imageName, index) => {
        const imageData = images[imageName]; // Access the image data using the filename as key
        const item_divs = document.createElement('div')
        item_divs.className = 'store_items'

        //Shop Template structure codes here...
        const template = `<div>
        <span class='overlay'>
            <button class="add_to_cart">Add to cart</button>
        </span>
        <img src="${imageData}" alt='store items'></div>
        <span class="shopping_items_details">
        <p>${itemsList[index]}</p>
        <span class="item_cart"><img src="${cart}" alt="cart"></span>
        </span>
        `


        item_divs.innerHTML = template
        item.appendChild(item_divs)
        

        

        item_divs.onclick = (e) => {
                console.log(e.target.parentElement)
                var get_clickOnce = localStorage.getItem(clickOnce_key)
                if(get_clickOnce=='false'){
                
                var storage_item_number = localStorage.getItem(item_number_key)
                var updateNumber =storage_item_number
                updateNumber++

                localStorage.setItem(item_number_key, updateNumber)
                updateDisplay()
                // console.log(new_item_number)
               

                const cart_div = document.createElement('div')
                const itemName = item_divs.querySelector('span p').textContent
                const itemImage = item_divs.querySelector('img').getAttribute('src')
                const cart_object = {
                    item_img: itemImage,
                    item_name: itemName,
                    id: '',
                    quantity: ''

                }

                const cart_template = `
                    <div class="cart_section_items" data-id=${id}>
                    <div><img src="${cart_object.item_img}"></div>
                    <div class="item_names">${cart_object.item_name}</div>
                    <div class="quantity">
                        <span class="decrease_item"><p>-</p></span>
                        <p class="no_item" data-id=${id}>1</p>
                        <span class="increase_item"><p>+</p></span>
                    </div>
                    <div class="bin">
                        <img src="${delete_icon}" alt="delete">
                    </div>
                    </div>
                `

                //Front end cart items on click begins here
                cartArray.push(cart_template)
                const cart_divs = document.createElement('div')
                cartArray.forEach(cart_items =>{
                    
                    cart_divs.innerHTML = cart_items
                    
                    items_container.appendChild(cart_divs)
                })


                //Local Storage cart items on click begins here...
                const get_cart_key = localStorage.getItem(cart_key)
                const convert_to_array = JSON.parse(get_cart_key)

                convert_to_array.push(cart_template)
                const local_stringify_array = JSON.stringify(convert_to_array)
                console.log(local_stringify_array)
                localStorage.setItem(cart_key,local_stringify_array)

                
               

                id++
                //click_once=true
                localStorage.setItem(clickOnce_key,true)
                clickOnce()

                }else{
                    alert('true')
                    console.log(get_clickOnce)
                }

        }

        document.addEventListener('click',e=>{
            const del = e.target.closest('.bin')

            if(del){
                localStorage.setItem(clickOnce_key,false)

                test=false
            }
        })


        //console.log(imageName, imageData); 
    });


    
  

    


    document.addEventListener('click',(e)=>{
        const target = e.target.closest('.increase_item')

        if(target){
           
            const previous_sibling = target.previousElementSibling
            var current_figue = Number(previous_sibling.textContent)
            current_figue++
            previous_sibling.innerHTML = current_figue
        }
    })


    document.addEventListener('click',(e)=>{
        const target = e.target.closest('.decrease_item')
        const delete_item = e.target.closest('.bin')

        if(target){
           
            const next_sibling = target.nextElementSibling
            var current_figue = Number(next_sibling.textContent)

            if(current_figue > 1){
                current_figue--
                next_sibling.innerHTML = current_figue
            }
            
        }

        if(delete_item){
            const parent = delete_item.parentElement
            new_item_number--
            items_number.textContent = new_item_number
            parent.remove()
            //console.log(parent)
        }

        
    })

   

    const updateCartItems=()=>{
    
        console.log(convert_to_array)
    
        convert_to_array.forEach(items=>{
            const cart_div = document.createElement('div')
            cart_div.innerHTML = items
            
            items_container.appendChild(cart_div)
        })
    
    //  const new_cart_array = []
    //  new_cart_array.push(get_cart_key)
    //  console.log(new_cart_array)
     
    //  new_cart_array.forEach(items=>{
    //      const cart_div = document.createElement('div')
    //      cart_div.innerHTML = items
    
    //      items_container.appendChild(cart_div)
         
    //  })
    } 
    updateCartItems()
   
    






    const arr = [

        gallery1,
        gallery2,
        gallery3,
        gallery4,
        gallery5,
        gallery6,


    ]

    //loop through gallery
    arr.forEach(items => {
        const div = document.createElement('div')

        const template = `
    <img src="${items}" alt="Adom tropical Foods shop">
    `

        div.innerHTML = template        //<div><img src="#" alt="Adom tropical Foods shop"></div>
        shop_gallery.appendChild(div)   // this appends into the Html

        div.onclick = () => {
            gallery.style.display = 'block'
            image.setAttribute('src', items)
        }


    })

    //close button functions
    close.onclick = () => {
        gallery.style.display = 'none'
    }

    right_arrow.onclick = () => {

        if (index < arr.length - 1) {
            index++
            image.setAttribute('src', arr[index])
            //console.log(index)
        }


    }

    left_arrow.onclick = () => {
        if (index > 0) {
            index--
            image.setAttribute('src', arr[index])
            //console.log(index)
        }
    }



} catch (error) {

}

if (mobile_view.matches) {
    setInterval(() => {
        try {
            if (feature_index < total_feature) {

                featured_trey.style.transform = `translateX(-${value += move}%)`
                //console.log(feature_index)
                feature_index++
            } else {
                value = 0
                feature_index = 0
                featured_trey.style.transform = `translateX(-${value}%)`
            }
        } catch (error) {

        }

    }, interval_time)
}






